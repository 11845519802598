import React, { useState, useEffect, useRef } from "react";
import Map, { Marker } from "react-map-gl/maplibre";

import maplibregl, { Map as MapboxMap } from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";

import Amenity from "../components/amenities/Amenity";
import Controls from "../components/controls/Controls";

import { Button, Col, Container, Row } from "react-bootstrap";
import pin from "../assets/images/locationpin.png";
import "./home.scss";
import Autocomplete from "../components/autocomplete/Autocomplete";
import { reversegeocodingUrl, mapStyleUrl, mapUrl } from "../urls/urls";
import directions from "../assets/images/get-direction.png";
import share from "../assets/images/sharebtn.png";
import cancel from "../assets/images/remove.png";
import Direction from "../components/directions/Direction";
import Panoromaview from "../components/360view/Panoromaview";
import { useMapContext } from "../components/context/Mapcontext";
import { useMap } from "react-map-gl/maplibre";
import Events from "../components/events/eventbox/Events";
import Eventcluster from "../components/events/eventclusters/Eventcluster";
import { useLocation, useNavigate } from "react-router-dom";

const Home = () => {
  // Default state values
  const [lng, setLng] = useState(85.32239);
  const [lat, setLat] = useState(27.677106);
  const [zoomLevel, setZoomLevel] = useState(13);

  const [srcLat, setSrcLat] = useState(null);
  const [srcLng, setSrcLng] = useState(null);
  const [dstLat, setDstLat] = useState(null);
  const [dstLng, setDstLng] = useState(null);
  const [zoomUrl, setZoomUrl] = useState(14);

  // state variables for panoroma images clicked latitude and longitude
  const [clickedLat, setClickedLat] = useState(null);
  const [clickedLng, setClickedLng] = useState(null);

  const { isBooleanValue, setBooleanValue, panoOn, setPanoOnValue } =
    useMapContext();
  const mapRef = useRef(null);
  const [polygonCoordinates, setPolygonCoordinates] = useState([]);
  const { cross, setCrossValue } = useMapContext();
  const [mapLayerStyle, setMapLayerStyle] = useState({});
  const [loadPanoData, setLoadPanoData] = useState(false);
  const [error, setError] = useState("");

  const { longPoints, latPoints, setLongPointsValue, setLatPointsValue } =
    useMapContext();

  const navigate = useNavigate();

  // useEffect(() => {
  //   const updateZoom = () => {
  //     const zoomNum = mapRef.current?.getZoom();
  //     const currentLatLng = mapRef.current?.getCenter();
  //     // if (currentLatLng) {
  //     //   setLatPointsValue(currentLatLng.lat);
  //     //   setLongPointsValue(currentLatLng.lng);
  //     // }

  //     const roundedZoomNum =
  //       zoomNum && !Number.isInteger(zoomNum)
  //         ? Math.round((zoomNum + Number.EPSILON) * 100) / 100
  //         : zoomNum;
  //     setZoomUrl(roundedZoomNum);
  //   };

  //   const mapInstance = mapRef.current;
  //   if (mapInstance) {
  //     mapInstance.on("moveend", updateZoom);
  //   }

  //   return () => {
  //     mapInstance?.off("moveend", updateZoom);
  //   };
  // }, [mapRef, setLatPointsValue, setLongPointsValue]);

  const location = useLocation();

  // // Parse URL parameters on load
  // useEffect(() => {
  //   const searchParams = new URLSearchParams(location.hash.slice(1)); // Skip `#`
  //   const latParam = parseFloat(searchParams.get("lat") || "");
  //   const lngParam = parseFloat(searchParams.get("lng") || "");
  //   const zoomParam = parseFloat(searchParams.get("zoom") || "");

  //   if (!isNaN(latParam) && !isNaN(lngParam)) {
  //     setLat(latParam);
  //     setLng(lngParam);
  //     setZoomLevel(zoomParam || DEFAULT_ZOOM);
  //   } else if (latPoints !== null && longPoints !== null) {
  //     setLat(latPoints);
  //     setLng(longPoints);
  //   }
  // }, [location, latPoints, longPoints]);

  // // Sync URL with map center and zoom state
  // useEffect(() => {
  //   // console.log(
  //   //   "latPoints:",
  //   //   latPoints,
  //   //   "longPoints:",
  //   //   longPoints,
  //   //   "zoomUrl:",
  //   //   zoomUrl
  //   // );
  //   if (lat && lng) {
  //     // Format the URL as "/lat,lng"
  //     const newPath = `/#${lat},${lng},${zoomUrl}`;
  //     navigate(newPath, { replace: true });
  //   }
  //   // else {
  //   //   const newPath = `/#${latPoints},${longPoints},${zoomUrl}`;
  //   //   navigate(newPath, { replace: true });
  //   // }
  // }, [lat, lng, navigate, zoomUrl]);

  const testingMapLoad = () => {
    setLoadPanoData(!loadPanoData);
  };

  useEffect(() => {
    const mapInstance = mapRef.current?.getMap();
    if (!mapInstance) return;
    if (loadPanoData) {
      mapInstance.setLayoutProperty("pano-layer", "visibility", "visible");
    } else {
      mapInstance.setLayoutProperty("pano-layer", "visibility", "none");
    }
  }, [loadPanoData]);

  const [currentZoomLevel, setCurrentZoomLevel] = useState(null);

  useEffect(() => {
    // const zoomNum = mapRef.current?.getZoom();
    if (mapRef.current && lat !== null && lng !== null) {
      mapRef.current?.flyTo({
        center: [lng, lat],
        zoom: zoomLevel,
        speed: 1, // Controls the speed of the flight animation
        curve: 1, // Controls the easing of the flight animation
        essential: true,
        duration: 2000,
      });
    }
  }, [lat, lng, zoomLevel]);

  // Track map center and zoom level dynamically when the user moves or zooms the map
  // useEffect(() => {
  //   if (mapRef.current) {
  //     const mapInstance = mapRef.current;

  //     const handleMove = () => {
  //       const center = mapInstance.getCenter();
  //       setLatPointsValue(center.lat);
  //       setLongPointsValue(center.lng);
  //     };

  //     const handleZoom = () => {
  //       const zoom = mapInstance.getZoom();
  //       setZoomLevel(zoom);
  //     };

  //     mapInstance.on("moveend", handleMove);
  //     mapInstance.on("zoomend", handleZoom);

  //     // Cleanup listeners on unmount
  //     return () => {
  //       mapInstance.off("moveend", handleMove);
  //       mapInstance.off("zoomend", handleZoom);
  //     };
  //   }
  // }, [setLatPointsValue, setLongPointsValue]);

  const settingZoomLevels = (lng, lat, zoomLevel) => {
    setLng(lng);
    setLat(lat);
    setZoomLevel(zoomLevel);
  };

  const [address, setAddress] = useState(""); // State variable to store the reverse geocoded address

  const reverseGeocode = async (lat, lng) => {
    const url = `${reversegeocodingUrl}&lat=${lat}&lng=${lng}`;

    try {
      const response = await fetch(url);
      const result = await response.json();
      // console.log(result.data);
      if (result.success) {
        // Store the result for the current coordinates
        result[`${lat},${lng}`] = result.data;
        return { success: true, data: result.data };
      } else {
        return { success: false, message: "Address not found", lat, lng };
      }
    } catch (error) {
      console.error("Error fetching reverse geocoding data:", error);
      return { success: false, message: "Error fetching address", lat, lng };
    }
  };

  const [toggle, setToggle] = useState(false);
  const [startAddress, setStartAddress] = useState("");
  const [endAddress, setEndAddress] = useState("");

  const { amenityWord, setAmenityValue } = useMapContext();
  const { list, setListValue } = useMapContext();

  //clearing reverse goecoding
  useEffect(() => {
    if (amenityWord !== "") {
      setToggle(false);
      setAddress("");
      setStartAddress("");
      setEndAddress("");
    }
    if (!isBooleanValue) {
      setToggle(false);
      setBooleanValue(isBooleanValue);
    }
  }, [amenityWord, isBooleanValue]);

  // Update the currentZoomLevel state when the zoom changes
  const handleZoomChange = (e) => {
    setZoomLevel(e.viewState.zoom);
  };

  const handleLatLng = async (e) => {
    // console.log(e);
    if (!marker) {
      setMarker(true);
    }
    if (amenityWord !== "") {
      setAmenityValue("");
      setBooleanValue(!isBooleanValue);
    }
    if (!list) {
      setListValue(true);
    }

    const { lng, lat } = e.lngLat;
    const result = await reverseGeocode(lat, lng);
    // Set the clicked latitude and longitude for panorama images
    setClickedLat(lat);
    setClickedLng(lng);
    settingZoomLevels(lng, lat, 18);

    // Simplified toggle logic
    setToggle(true);

    setCrossValue(!cross);

    if (result.success) {
      // If the generalName is "-,", turn off toggle and panoOn, and skip setting the address
      if (result.data.generalName === "-,") {
        setToggle(false); // Turn off toggle
        setPanoOnValue(false); // Turn off panoOn
        // Do not set the address here
      } else {
        // Set the address and related fields if generalName is valid
        setAddress(result.data);
        setEndAddress(result.data.generalName);
        setDstLat(lat);
        setDstLng(lng);

        // Handle panoOn toggle logic
        if (panoOn) {
          setPanoOnValue(panoOn);
        } else {
          setPanoOnValue(!panoOn);
        }
      }
    } else {
      // Handle error scenario
      console.error(result.message);
      setAddress(result.message); // Set error message as address (optional, based on use case)
    }
  };

  const handleClear = () => {
    setToggle(!toggle);
    setSrcLat(null);
    setSrcLng(null);
    setDstLat(null);
    setDstLng(null);
    setPanoOnValue(false);
  };

  const { direction, setDirectionValue } = useMapContext();
  // const [direction, setDirection] = useState(false);

  const [marker, setMarker] = useState(true);

  const handleClick = () => {
    setDirectionValue(!direction);
    // Update the panorama view styles when the direction button is clicked
    setBooleanValue(!isBooleanValue);
    setMarker(false);
  };

  return (
    <>
      <Map
        ref={mapRef}
        mapLib={maplibregl}
        initialViewState={{
          longitude: lng,
          latitude: lat,
          zoom: zoomLevel,
        }}
        style={{ width: "100%", minHeight: "100vh" }}
        mapStyle={mapStyleUrl}
        attributionControl={false}
        onClick={handleLatLng}
        onZoomChange={handleZoomChange}
      >
        {toggle && (
          <>
            <div className="reverse-geocode">
              {marker && (
                <Marker longitude={lng} latitude={lat} anchor="bottom">
                  <img src={pin} alt="pin" style={{ height: "40px" }} />
                </Marker>
              )}
              {marker && (
                <div
                  className={isBooleanValue ? "pointinfo-select" : "point-info"}
                >
                  <div className="point-info-inner">
                    <div
                      className="point-title"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <>
                        <span>
                          {address.pois
                            ? address.pois.location.split(",").slice(0, 1)
                            : address?.generalName
                                ?.split(",")
                                .slice(0, 3)
                                .join(",") ||
                              address.name ||
                              address.tole ||
                              address.place ||
                              address.municipality}
                        </span>
                        <img src={cancel} alt="close" onClick={handleClear} />
                      </>
                      {/* <Row>
                        <Col md={10}>
                          <span>
                            {address.pois
                              ? address.pois.location.split(",").slice(0, 1)
                              : address?.generalName
                                  ?.split(",")
                                  .slice(0, 3)
                                  .join(",") ||
                                address.name ||
                                address.tole ||
                                address.place ||
                                address.municipality}
                          </span>

                         
                        </Col>
                        <Col md={2}>
                          <img src={cancel} alt="close" onClick={handleClear} />
                        </Col>
                      </Row> */}
                    </div>

                    <small>
                      {address.gallicode
                        ? address.gallicode
                        : ` ${address.municipality}-${address.ward}`}
                    </small>

                    <p>
                      {lat.toPrecision(8)}, {lng.toPrecision(8)}
                    </p>
                    <hr />
                  </div>

                  <div className="point-direction">
                    <Button
                      className="direction-btn"
                      onClick={() => {
                        handleClick();
                      }}
                      // href="/directions"
                    >
                      <img src={directions} alt="get-direction" />

                      <span>Directions</span>
                    </Button>
                    <Button className="share-btn">
                      <img src={share} alt="share" />
                    </Button>
                  </div>
                </div>
              )}

              {direction && (
                <div>
                  <Direction
                    secondLat={dstLat}
                    secondLng={dstLng}
                    stop={endAddress}
                  />
                </div>
              )}
            </div>
          </>
        )}
        <Row>
          <Col sm={12} md={4} lg={4}>
            <Autocomplete settingZoomLevels={settingZoomLevels} />
          </Col>

          <Col sm={12} md={8} lg={8}>
            <Amenity settingZoomLevels={settingZoomLevels} />
          </Col>
        </Row>
        /
        <Row>
          <Col md={4}></Col>
          <Col md={8} style={{ position: "relative" }}>
            <Eventcluster />
          </Col>
        </Row>
        {panoOn && (
          <Panoromaview latitude={clickedLat} longitude={clickedLng} />
        )}
        <Controls testingMapLoad={testingMapLoad} />
      </Map>
    </>
  );
};

export default Home;
