import Allroutes from "./Routes/Allroutes";
import "bootstrap/dist/css/bootstrap.min.css";
import { MapProvider } from "./components/context/Mapcontext";
import Minimizedmap from "./components/minimizedmap/Minimizedmap";

function App() {
  return (
    <div className="App">
      <MapProvider>
        <Allroutes />
      </MapProvider>
    </div>
  );
}

export default App;
